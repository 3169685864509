import React from 'react'
import {Link} from 'gatsby'
import BlogSidebar from './BlogSidebar'
import img6 from '../../assets/images/blog/LifeStyleChoices.png'
import img4 from '../../assets/images/blog/blog-img4.jpg'
import img5 from '../../assets/images/blog/blog-img5.jpg'
import img7 from '../../assets/images/blog/blog-img7.jpg'
import img11 from '../../assets/images/blog/blog-img11.jpg'
import img12 from '../../assets/images/blog/blog-img12.jpg'
import user1 from '../../assets/images/user1.jpg'
import user2 from '../../assets/images/user2.jpg'
import user3 from '../../assets/images/user3.jpg'
import user4 from '../../assets/images/user4.jpg'

const BlogDetailsContent = () => {
    return (
        <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img src={img6} alt="bb" />
                            </div>

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <i className='bx bx-group'></i>
                                            <span>Posted By</span>
                                            <Link to="#">
                                                Kylie Bell
                                            </Link>
                                        </li>
                                        <li>
                                            <i className='bx bx-calendar'></i>
                                            <span>Last Updated</span>
                                            <Link to="#">
                                                03/12/2021
                                            </Link>
                                        </li>
                                        <li>
                                            <i className='bx bx-folder-open'></i>
                                            <span>Category</span>
                                            <Link to="/blog">
                                                Life Style
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <h3>Life style choices that will extend your life</h3>

                                <p>Getting the right amount of Sleep</p>

                                <p>Getting the right amount of sleep, and doing so regularly, is first on the list. It's often missed because people focus on diet and exercise.</p>

                                <p>A good night's sleep is important to recharge both the body and mind. It helps the body repair cells and get rid of wastes. It also is important in making memories, and sleep deprivation leads to forgetfulness.</p>

                                <h3>Eating Well-Balanced Meals</h3>

                                <p>A healthy diet gives you energy and lowers your risk for heart disease, diabetes, cancer, and other diseases.</p>

                                <p>Taking steps toward a lifelong change in diet will help more than jumping on the latest fad diet does.</p>

                                <p>One place to begin is with the well-regarded Mediterranean diet. It's rich in many of the healthiest foods and naturally limits less healthy choices. </p>

                                <p>The Mediterranean diet has a lot of fruits and vegetables, whole grains, "good" oils, and plenty of herbs and spices. It doesn't have highly processed foods, refined grains, or added sugar.</p>

                                <h3>Making Time for Physical Activity</h3>

                                <p>Thirty minutes a day of physical activity protects heart health. It also lowers the amount of bone loss as you age.</p>

                                <p>Best of all, physical activity is a low-cost way to boost your health and even save you money. Sometimes your health may limit your exercise options, but you can keep moving by washing your windows, mowing your lawn, sweeping a sidewalk, and other basic tasks.</p>

                                <p>Once you are past age 65, you may benefit by adding balance and flexibility exercises, but keep moving too.</p>

                                <h3>Keeping a Healthy Body Weight</h3>

                                <p>Obesity is associated with a shorter lifespan and a higher risk of many diseases. The good news is that just being somewhat overweight does not reduce your longevity.</p>

                                <h3>Smoking</h3>

                                <p>The dangers of smoking have been well documented. It reduces your life span and quality of life as you age.</p>

                                <h3>Alcohol</h3>

                                <p>High Levels of alchol consumption (more than 1 drink per day for women and 2 drinks per day for men) can lead to an increased chance of:</p>

                                <p>Stroke</p>

                                <p>High Blood Pressure</p>

                                <p>Heart disease</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <BlogSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogDetailsContent